import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
var ERROR_MESSAGE = '请求未响应'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000
}); // request interceptor

service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    // 请求接口添加上Authorization
    config.headers['Authorization'] = "Bearer ".concat(getToken());
  }

  return config;
}, function (error) {
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/*
 * 请求成功相应
 */
function (response) {
  var res = response.data; // 请求成功状态码:0

  if (res.status !== 0) {
    Message({
      message: res.message || ERROR_MESSAGE,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || ERROR_MESSAGE));
  } else {
    return Promise.resolve(res);
  }
},
/*
 * 请求失败
 */
function (error) {
  console.log('err', error); // for debug
  // 401 Author失效

  if (error.response && error.response.status === 401) {
    // to re-login
    Message({
      message: '你的登录认证已经过期,请重新登录',
      type: 'warning',
      duration: 5 * 1000
    });
    store.dispatch('user/resetToken').then(function () {
      location.reload();
    });
    return Promise.reject(error);
  } // 400 数据异常


  if (error.response && error.response.status === 400) {
    var data = error.response.data;

    if (data && data.status) {
      Message({
        message: data.message || ERROR_MESSAGE,
        type: 'error',
        duration: 5 * 1000
      });
    }

    return Promise.reject(error);
  } // 其他,请求未响应


  Message({
    message: ERROR_MESSAGE,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;