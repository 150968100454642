import { join as _join, query as _query, detail as _detail, joinedList as _joinedList, canJoinList as _canJoinList } from '@/api/activity';

var getDefaultState = function getDefaultState() {
  return {
    lists: {}
  };
};

var state = getDefaultState();
var mutations = {
  SET_ACTIVITY_LIST: function SET_ACTIVITY_LIST(state, list) {
    state.lists = list;
  },
  RESET_ACTIVITY_LIST: function RESET_ACTIVITY_LIST(state) {
    state.lists = [];
  }
};
var actions = {
  // 报名参加活动
  'join': function join(_ref, store_id, activity_id) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _join(store_id, activity_id).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 查询是否参加
  'query': function query(_ref2, store_id, activity_id) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _query(store_id, activity_id).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 活动详情
  'detail': function detail(_ref3, activity_id) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _detail(activity_id).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 可参加报名的活动
  'can-join-list': function canJoinList(_ref4, store_id) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      _canJoinList(store_id).then(function (response) {
        var data = response.data;
        commit('SET_ACTIVITY_LIST', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 已报名的活动
  'joined-list': function joinedList(_ref5, store_id) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      _joinedList(store_id).then(function (response) {
        var data = response.data;
        commit('SET_ACTIVITY_LIST', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 重置活动列表
  'reset-activity-list': function resetActivityList(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit('RESET_ACTIVITY_LIST');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};