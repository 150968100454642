import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/auth',
    method: 'post',
    data: data
  });
}
export function info() {
  return request({
    url: "/user/userinfo",
    method: 'get'
  });
}
export function phoneCode(phone) {
  return request({
    url: "/sms/register_code",
    method: 'post',
    data: {
      phone: phone
    }
  });
}
export function checkCode(data) {
  return request({
    url: "/user/check_code",
    method: 'post',
    data: data
  });
}
export function updatePassword(data) {
  return request({
    url: "/user/modity_password",
    method: 'post',
    data: data
  });
}
export function logout() {
  return new Promise(function (resolve) {
    resolve();
  });
}