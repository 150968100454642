import { bankInfo as _bankInfo } from '@/api/config';

var getDefaultState = function getDefaultState() {
  return {};
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  }
};
var actions = {
  // user login
  'bank-info': function bankInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _bankInfo().then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};