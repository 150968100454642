import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import settings from "./modules/settings";
import oss from "./modules/oss";
import activity from "./modules/activity";
import shop from "./modules/shop";
import workorder from "./modules/workorder";
import model from "./modules/model";
import user from "./modules/user";
import config from "./modules/config";
import dashboard from "./modules/dashboard";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    settings: settings,
    user: user,
    oss: oss,
    activity: activity,
    shop: shop,
    workorder: workorder,
    model: model,
    config: config,
    dashboard: dashboard
  },
  getters: getters
});
export default store;