import "core-js/modules/es6.regexp.to-string";
import "/home/jenkins/workspace/\u6D4B\u8BD5-\u949B\u7075AI\u5E02\u573A-\u5546\u6237\u540E\u53F0/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/jenkins/workspace/\u6D4B\u8BD5-\u949B\u7075AI\u5E02\u573A-\u5546\u6237\u540E\u53F0/node_modules/core-js/modules/es6.promise.js";
import "/home/jenkins/workspace/\u6D4B\u8BD5-\u949B\u7075AI\u5E02\u573A-\u5546\u6237\u540E\u53F0/node_modules/core-js/modules/es6.object.assign.js";
import "/home/jenkins/workspace/\u6D4B\u8BD5-\u949B\u7075AI\u5E02\u573A-\u5546\u6237\u540E\u53F0/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import CryptoJS from 'crypto-js';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/permission'; // permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
      mockXHR = _require.mockXHR;

  mockXHR();
} // set ElementUI lang to EN


Vue.use(ElementUI, {
  locale: locale
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;

Vue.prototype.$mediaUrl = function (obj) {
  if (obj == null) {
    return null;
  }

  if (obj.media_url) {
    return obj.media_url;
  }

  if (obj[0] && obj[0].media_url) {
    return obj[0].media_url;
  }

  if (obj.url) {
    return obj.url;
  }

  if (obj[0] && obj[0].url) {
    return obj[0].url;
  }

  return null;
};

Vue.prototype.$encodePassword = function (string) {
  var pass = 'TIORBPASSWORDKEY';
  var key = CryptoJS.enc.Utf8.parse(pass);
  var encryptedData = CryptoJS.AES.encrypt(string, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  encryptedData = encryptedData.ciphertext.toString();
  console.log("\u52A0\u5BC6\u540E-no-hex\uFF1A".concat(encryptedData));
  return encryptedData;
};

Vue.prototype.$currencyUnit = function (value) {
  if (value === 1) {
    return '元';
  }

  if (value === 2) {
    return '港币';
  }

  if (value === 3) {
    return '美元';
  }

  return '';
};

new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
import VueCropper from 'vue-cropper';
Vue.use(VueCropper);