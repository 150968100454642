import Cookies from 'js-cookie';
var TokenKey = 'vue_admin_template_token';
var AccountKey = 'vue_admin_template_account';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getAccount() {
  return Cookies.get(AccountKey);
}
export function setAccount(info) {
  return Cookies.set(AccountKey, info);
}
export function removeAccount() {
  return Cookies.remove(AccountKey);
}