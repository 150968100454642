/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  return str.trim().length > 0;
}
/**
 * 验证删选条件非空
 **/

export function validClassifyList(rule, value, callback) {
  if (value && value.length > 0) {
    callback();
  } else {
    callback(new Error());
  }
}
/**
 * 手机号合法性
 **/

export function validPhoneNumber(value) {
  return /^1[3|4|5|7|8][0-9]{9}$/.test(value);
}
/**
 * 邮箱合法性
 **/

export function validEmail(value) {
  return /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value);
}