import { data as _data } from '@/api/dashboard';
var actions = {
  // user login
  'data': function data(_ref) {
    var commit = _ref.commit,
        state = _ref.state;
    return new Promise(function (resolve, reject) {
      _data().then(function (response) {
        if (response.status === 0) {
          resolve(response.data);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  actions: actions
};