import request from '@/utils/request'; // 获取算法列表

export function list(data) {
  return request({
    url: '/algorithm/search',
    method: 'post',
    data: data
  });
} // 获取算法详情

export function detail(id) {
  return request({
    url: '/algorithm/algorithm_info',
    method: 'post',
    data: {
      id: id
    }
  });
} // 更新算法

export function update(data) {
  return request({
    url: '/algorithm/edit',
    method: 'post',
    data: data
  });
} // 添加算法

export function add(data) {
  return request({
    url: '/algorithm/add',
    method: 'post',
    data: data
  });
} // 删除算法

export function del(ids) {
  return request({
    url: '/algorithm/delete',
    method: 'post',
    data: {
      ids: ids
    }
  });
} // 上架算法

export function enable(ids) {
  return request({
    url: '/algorithm/upper_shel',
    method: 'post',
    data: {
      ids: ids
    }
  });
} // 下架算法

export function disable(ids) {
  return request({
    url: '/algorithm/lower_shel',
    method: 'post',
    data: {
      ids: ids
    }
  });
} // 获取全部搜索条件

export function condition() {
  var lang = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return request({
    url: '/algorithm/query_all_condition/' + lang,
    method: 'get'
  });
}