import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon'; // svg component

import FilterItem from '@/components/FilterItem'; // register globally

Vue.component('svg-icon', SvgIcon);
Vue.component('ti-filter-item', FilterItem);

var req = require.context('./svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);