import { enterpriseDetail as _enterpriseDetail, enterpriseUpdate as _enterpriseUpdate, personalDetail as _personalDetail, personalUpdate as _personalUpdate } from '@/api/shop';

var getDefaultState = function getDefaultState() {
  return {
    info: {}
  };
};

var state = getDefaultState();
var mutations = {
  SET_SHOP_INFO: function SET_SHOP_INFO(state, info) {
    state.info = info;
  },
  RESET_SHOP_INFO: function RESET_SHOP_INFO(state) {
    state.info = {};
  }
};
var actions = {
  // 企业商户详情
  'enterprise-detail': function enterpriseDetail(_ref, id) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _enterpriseDetail(id).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('商户信息为空.');
        }

        commit('SET_SHOP_INFO', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 更新企业商户信息
  'enterprise-update': function enterpriseUpdate(_ref2, data) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _enterpriseUpdate(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 个人商户详情
  'personal-detail': function personalDetail(_ref3, id) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _personalDetail(id).then(function (response) {
        var data = response.data;

        if (response.status !== 0) {
          reject(response.message);
          return;
        }

        if (!data) {
          reject('商户信息为空.');
          return;
        }

        commit('SET_SHOP_INFO', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 更新个人商户信息
  'personal-update': function personalUpdate(_ref4, data) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      _personalUpdate(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  'reset-shop-info': function resetShopInfo(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('RESET_SHOP_INFO');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};