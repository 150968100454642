import _objectSpread from "/home/jenkins/workspace/\u6D4B\u8BD5-\u949B\u7075AI\u5E02\u573A-\u5546\u6237\u540E\u53F0/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import { list as _list, detail as _detail, add, del, disable as _disable, update as _update, enable as _enable, condition as _condition } from '@/api/model';

var getDefaultState = function getDefaultState() {
  return {
    detail: {
      list: []
    }
  };
};

var state = getDefaultState();
var mutations = {
  CLEAR_MODEL: function CLEAR_MODEL(state) {
    state.workorders = [];
  },
  SET_MODEL_LIST: function SET_MODEL_LIST(state, lists) {
    state.list = lists;
  }
};
var actions = {
  // 获取算法(模型)列表
  'list': function list(_ref, params) {
    var commit = _ref.commit,
        state = _ref.state;
    return new Promise(function (resolve, reject) {
      _list(params).then(function (response) {
        var data = response.data;
        commit('SET_MODEL_LIST', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取算法详情
  'detail': function detail(_ref2, id) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _detail(id).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 添加算法
  'create': function create(_ref3, model) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      add(_objectSpread(_objectSpread({}, model), {
        language: 1
      })).then(function (response) {
        if (response.status === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 删除算法
  'delete': function _delete(_ref4, ids) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      del(ids).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 更新算法
  'update': function update(_ref5, model) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      _update(_objectSpread(_objectSpread({}, model), {
        language: 1
      })).then(function (response) {
        if (response.status === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 上架算法
  'enable': function enable(_ref6, ids) {
    var commit = _ref6.commit,
        state = _ref6.state;
    return new Promise(function (resolve, reject) {
      _enable(ids).then(function (response) {
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 下架算法
  'disable': function disable(_ref7, ids) {
    var commit = _ref7.commit,
        state = _ref7.state;
    return new Promise(function (resolve, reject) {
      _disable(ids).then(function (response) {
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取全部分类
  'condition': function condition(_ref8) {
    var commit = _ref8.commit,
        state = _ref8.state;
    return new Promise(function (resolve, reject) {
      _condition().then(function (response) {
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 清除咨询单列表
  'clear-model': function clearModel(_ref9) {
    var commit = _ref9.commit;
    return new Promise(function (resolve) {
      commit('CLEAR_MODEL');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};