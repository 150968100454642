import { upload, path } from '@/api/oss';
var actions = {
  // user login
  'uploader': function uploader(_ref, fileObject) {
    var commit = _ref.commit,
        state = _ref.state;
    return new Promise(function (resolve, reject) {
      console.log(fileObject);
      upload({
        file: fileObject.file,
        type: fileObject.type,
        user_id: fileObject.user_id,
        describe: fileObject.describe
      }).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('文件上传失败.');
          return;
        }

        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  'load-path': function loadPath() {
    return path();
  }
};
export default {
  namespaced: true,
  actions: actions
};