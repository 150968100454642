import request from '@/utils/request'; // 查询企业商户信息

export function enterpriseDetail(id) {
  return request({
    url: '/merchant/query_enterprise/' + id,
    method: 'get'
  });
} // 更新企业商户信息

export function enterpriseUpdate(data) {
  return request({
    url: '/merchant/update_enterprise',
    method: 'post',
    data: data
  });
} // 查询个人商户信息

export function personalDetail(id) {
  return request({
    url: '/merchant/query_personal/' + id,
    method: 'get'
  });
} // 更新个人商户信息

export function personalUpdate(data) {
  return request({
    url: '/merchant/update_personal',
    method: 'post',
    data: data
  });
}