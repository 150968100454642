import { login as _login, logout as _logout, info as _info, phoneCode as _phoneCode, checkCode, updatePassword as _updatePassword } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';

var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    info: undefined,
    loginInfo: undefined
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER_INFO: function SET_USER_INFO(state, user) {
    state.info = user;
  },
  GET_USER_INFO: function GET_USER_INFO(state) {
    return state.info;
  },
  SET_LOGIN_INFO: function SET_LOGIN_INFO(state, info) {
    state.loginInfo = info;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.jwt);
        commit('SET_LOGIN_INFO', data.user);
        setToken(data.jwt);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  info: function info(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _info().then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        commit('SET_USER_INFO', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        removeToken(); // must remove  token  first

        resetRouter();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get phone code
  'phone-code': function phoneCode(_ref4, phone) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _phoneCode(phone).then(function (resp) {
        resolve(resp);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // check phone code
  'check-phone-code': function checkPhoneCode(_ref5, data) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      checkCode(data).then(function (resp) {
        resolve(resp);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // update password
  'update-password': function updatePassword(_ref6, data) {
    var commit = _ref6.commit,
        state = _ref6.state;
    return new Promise(function (resolve, reject) {
      _updatePassword(data).then(function (resp) {
        resolve(resp);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref7) {
    var commit = _ref7.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first

      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};