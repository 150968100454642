import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/account/login');
  },
  hidden: true
}, {
  path: '/forget-password',
  component: function component() {
    return import('@/views/account/forget-password');
  },
  hidden: true
}, {
  path: '/reset-password',
  component: function component() {
    return import('@/views/account/reset-password');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '仪表板',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/ai-products',
  component: Layout,
  children: [{
    path: 'index',
    name: 'AiProducts',
    component: function component() {
      return import('@/views/ai-products/index');
    },
    meta: {
      title: 'AI商品管理',
      icon: 'product'
    }
  }]
}, {
  path: '/demands',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Demands',
    component: function component() {
      return import('@/views/demands/index');
    },
    meta: {
      title: '咨询单管理',
      icon: 'form'
    }
  }]
}, // {
//   path: '/activity',
//   component: Layout,
//   children: [{
//     path: 'index',
//     name: 'Activity',
//     component: () => import('@/views/activity/index'),
//     meta: { title: '营销活动', icon: 'activity' }
//   }]
// },
{
  path: '/shop-profile',
  component: Layout,
  children: [{
    path: 'shop-profile',
    name: 'ShopProfile',
    component: function component() {
      return import('@/views/shop-profile/index');
    },
    meta: {
      title: '店铺配置',
      icon: 'setting'
    }
  }]
}, {
  path: '/notice',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Notice',
    component: function component() {
      return import('@/views/notice/index');
    },
    meta: {
      title: '通知',
      icon: 'product'
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;