import request from '@/utils/request'; // 咨询单列表

export function list(data) {
  return request({
    url: '/con_sheet/list',
    method: 'post',
    data: data
  });
} // 咨询单详情

export function detail(cs_id) {
  return request({
    url: '/con_sheet/detail/' + cs_id,
    method: 'get'
  });
} // 咨询单留言回复

export function replay(data) {
  return request({
    url: '/con_sheet/reply',
    method: 'post',
    data: data
  });
} // 咨询单留言回复

export function payment(data) {
  return request({
    url: '/con_sheet/payment',
    method: 'post',
    data: data
  });
} // 查询和自己相关的所有消息

export function message(data) {
  return request({
    url: '/con_sheet/query_message',
    method: 'post',
    data: data
  });
} // 设置未读消息为已读

export function messageMarkRead(data) {
  return request({
    url: '/con_sheet/set_message_read',
    method: 'post',
    data: data
  });
} // 设置未读消息为已读

export function messageDelete(data) {
  return request({
    url: '/con_sheet/del_message',
    method: 'post',
    data: data
  });
}