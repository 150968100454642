import request from '@/utils/request';
/**
 * OSS 文件上传
 * @param data
 * file:文件
 * type:上传的文件类型(image:图片,document:文档)
 * user_id:上传者id(用户UID)
 * describe:描述
 */

export function upload(data) {
  // 文件包装成Form形式
  var formData = new FormData();

  for (var index in data) {
    formData.append(index, data[index]);
  }

  data = formData;
  return request({
    url: '/oss/upload',
    method: 'post',
    data: data
  });
}
export function path() {
  return "".concat(process.env.VUE_APP_BASE_API, "/oss/upload");
}