var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.timestamps,
      staticClass: "notranslate",
      attrs: { id: "app", translate: "no" }
    },
    [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }