//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import logoFile from "../../../assets/images/logo.png";
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '钛灵AI市场商户后台',
      logo: logoFile
    };
  }
};