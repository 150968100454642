//
//
//
//
//
//
//
export default {
  name: 'TiFilterItem',
  props: {
    label: String
  }
};