import request from '@/utils/request';
/**
 * OSS 文件上传
 * @param data
 * file:文件
 * type:上传的文件类型(image:图片,document:文档)
 * user_id:上传者id(用户UID)
 * describe:描述
 */

export function data() {
  return request({
    url: 'index'
  });
}