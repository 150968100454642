import { list, detail as _detail, message, messageDelete, messageMarkRead, replay as _replay, payment as _payment } from '@/api/workorder';

var getDefaultState = function getDefaultState() {
  return {
    detail: {
      workorder: [],
      message: []
    }
  };
};

var state = getDefaultState();
var mutations = {
  CLEAR_WORKORDER: function CLEAR_WORKORDER(state) {
    state.workorders = [];
  },
  SET_WORKORDER_LIST: function SET_WORKORDER_LIST(state, lists) {
    state.workorders = lists;
  },
  CLEAR_MESSAGE: function CLEAR_MESSAGE(state) {
    state.message = [];
  },
  SET_MESSAGE_LIST: function SET_MESSAGE_LIST(state, message) {
    state.message = message;
  }
};
var actions = {
  // 获取列表
  'lists': function lists(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      list(data).then(function (response) {
        var data = response.data;
        commit('SET_WORKORDER_LIST', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 回复咨询单
  'replay': function replay(_ref2, params) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _replay(params).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取咨询单详情
  detail: function detail(_ref3, cs_id) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _detail(cs_id).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取咨询单详情
  payment: function payment(_ref4, data) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _payment(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取消息列表
  'messages': function messages(_ref5, data) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      message(data).then(function (response) {
        if (response.status === 0) {
          var _data = response.data;
          commit('SET_MESSAGE_LIST', _data);
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 删除消息
  'messages-delete': function messagesDelete(_ref6, data) {
    var commit = _ref6.commit,
        state = _ref6.state;
    return new Promise(function (resolve, reject) {
      messageDelete(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 标记为已读
  'messages-mark-read': function messagesMarkRead(_ref7, data) {
    var commit = _ref7.commit,
        state = _ref7.state;
    return new Promise(function (resolve, reject) {
      messageMarkRead(data).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 清除咨询单列表
  'clear-workorder': function clearWorkorder(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve) {
      commit('CLEAR_WORKORDER');
      resolve();
    });
  },
  // 清除消息列表
  'clear-messages': function clearMessages(_ref9) {
    var commit = _ref9.commit;
    return new Promise(function (resolve) {
      commit('CLEAR_MESSAGE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};