//
//
//
//
//
//
export default {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload,
      isIE: this.isIE
    };
  },
  data: function data() {
    return {
      timestamps: Date.now(),
      isRouterAlive: true
    };
  },
  watch: {
    $route: function $route(to, from) {
      if (to.fullPath !== from.fullPath) {
        document.documentElement.scrollTop = 0;
      }
    }
  },
  methods: {
    reload: function reload() {
      var _this = this;

      this.isRouterAlive = false;
      this.$nextTick(function () {
        _this.isRouterAlive = true;
        document.documentElement.scrollTop = 0;
      });
    },
    isIE: function isIE() {
      return !!window.ActiveXObject || 'ActiveXObject' in window;
    }
  }
};