import request from '@/utils/request'; // 我参与的活动

export function joinedList(store_id) {
  return request({
    url: "/activity/joined/".concat(store_id),
    method: 'get'
  });
} // 我可以参与的活动

export function canJoinList(store_id) {
  return request({
    url: "/activity/can_join/".concat(store_id),
    method: 'get'
  });
} // 报名参加活动

export function join(store_id, activity_id) {
  return request({
    url: '/activity/join',
    method: 'post',
    params: {
      store_id: store_id,
      activity_id: activity_id
    }
  });
} // 查询是否参加

export function query(store_id, activity_id) {
  return request({
    url: '/activity/is_join',
    method: 'post',
    params: {
      store_id: store_id,
      activity_id: activity_id
    }
  });
} // 活动详情

export function detail(activity_id) {
  return request({
    url: "/activity/detail/".concat(activity_id),
    method: 'get'
  });
}